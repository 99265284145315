<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M5.36683 7.45522L0.266831 2.37421C0.182929 2.29141 0.116309 2.19278 0.0708384 2.08403C0.025368 1.97528 0.00195313 1.85858 0.00195312 1.74071C0.00195312 1.62283 0.025368 1.50613 0.0708384 1.39738C0.116309 1.28863 0.182929 1.19 0.266831 1.10721L1.11483 0.263214C1.28358 0.0946735 1.51233 0 1.75083 0C1.98933 0 2.21808 0.0946735 2.38683 0.263214L6.00283 3.86322L9.61883 0.263214C9.78758 0.0946735 10.0163 0 10.2548 0C10.4933 0 10.7221 0.0946735 10.8908 0.263214L11.7388 1.10721C11.8227 1.19 11.8894 1.28863 11.9348 1.39738C11.9803 1.50613 12.0037 1.62283 12.0037 1.74071C12.0037 1.85858 11.9803 1.97528 11.9348 2.08403C11.8894 2.19278 11.8227 2.29141 11.7388 2.37421L6.63883 7.45522C6.47025 7.62254 6.24235 7.71643 6.00483 7.71643C5.76731 7.71643 5.53942 7.62254 5.37083 7.45522H5.36683Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
